import React from "react";
import styled from "styled-components";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import Container from "../components/Container";
import { Flex, Box } from "@rebass/grid";
import H1 from "../components/H1";

const Header = styled.div`
  text-align: center;
`;

export const ContentPageTemplate = ({ title, content, contentComponent }) => {
  const PageContent = contentComponent || Content;

  return (
    <Container>
      <Helmet title={`${title} | Appmantle`} />
      <Flex justifyContent="center">
        <Box width={[1, 10 / 12, 8 / 12]} my={[3, 4, 5]}>
          <Header>
            <H1 as="h1">{title}</H1>
          </Header>
          <PageContent className="content" content={content} />
        </Box>
      </Flex>
    </Container>
  );
};

const ContentPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <ContentPageTemplate
        contentComponent={HTMLContent}
        title={post.frontmatter.title}
        content={post.html}
      />
    </Layout>
  );
};

export default ContentPage;

export const aboutPageQuery = graphql`
  query ContentPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`;
